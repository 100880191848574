import { render, staticRenderFns } from "./MainCard.vue?vue&type=template&id=4b1759c2"
import script from "./MainCard.vue?vue&type=script&lang=js"
export * from "./MainCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CommonsPopup: require('/var/app/components/Commons/Popup.vue').default,Theme1Announcement: require('/var/app/components/Theme1/Announcement.vue').default,Theme1Slider: require('/var/app/components/Theme1/Slider.vue').default,Theme1MobileCards: require('/var/app/components/Theme1/Mobile/Cards.vue').default,Theme1DesktopCards: require('/var/app/components/Theme1/Desktop/Cards.vue').default})
